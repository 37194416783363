<!--
 * @Author: cyy 1346967714@qq.com
 * @version: 1.4.35
 * @Date: 2023-03-02 16:25:14
 * @LastEditTime: 2023-03-13 15:58:50
 * @LastEditors: cyy 1346967714@qq.com
 * @Description: 页面留存设置
 * @FilePath: \teacherdingding.com\src\views\coursePromotion\pageRetention.vue
-->
<template>
  <div class="pageretention">
    <el-dialog
      title="页面留存设置"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-form
          ref="form"
          label-position="left"
          :model="form"
          label-width="80px"
        >
          <el-form-item>
            <el-radio-group v-model="form.preserve">
              <el-radio label="1">开启</el-radio>
              <el-radio label="2">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="form.preserve == 1">
            <el-form-item label="标题">
              <el-input
                show-word-limit
                maxlength="20"
                style="width:400px"
                v-model="form.preserve_title"
              ></el-input>
            </el-form-item>
            <el-form-item label="背景图片">
              <div class="coustom_contain">
                <div class="tu">
                  <img
                    v-if="form.preserve_img"
                    :src="form.preserve_img"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="tip">
                    建议尺寸500*500px，JPG、PNG格式，图片小于2M
                  </div>
                  <el-button size="medium" style="width: 100px" @click="upload">
                    选择图片
                  </el-button>
                  <el-button
                    size="medium"
                    style="width: 100px"
                    class="ml20"
                    v-if="form.preserve_img"
                    @click="delImg"
                  >
                    删除图片
                  </el-button>
                </div>
              </div>
              <changePhotoimg
                :isshow="true"
                ref="changePhotoimg"
                @complete="complete"
                class="changePhotoimg"
                :size="2"
              ></changePhotoimg>
            </el-form-item>
            <el-form-item label="按钮文字" :required="true">
              <el-input
                style="width:400px"
                show-word-limit
                maxlength="10"
                v-model="form.preserve_button"
              ></el-input>
            </el-form-item>
          </template>
        </el-form>
        <div class="example" v-if="form.preserve == 1">
          <div class="fs14">示例:</div>
          <div class="contain">
            <div class="tu">
              <i class="icon_close">x</i>
              <img :src="form.preserve_img" v-if="form.preserve_img" alt="" />
              <div class="title_button">
                <div class="title">{{ form.preserve_title }}</div>
                <div class="button">{{ form.preserve_button }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit" size="medium">
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
export default {
  name: 'pageretention',
  components: {
    changePhotoimg,
  },
  data() {
    return {
      form: {
        preserve: '2',
        preserve_title: '确定要离开吗？',
        preserve_img: '',
        preserve_button: '继续报名',
      },
    }
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    delImg() {
      this.form.preserve_img = ''
    },

    // 选择图片
    upload() {
      this.$refs.changePhotoimg.changePicture()
    },
    async getData() {
      const { data } = await this.$http({
        url: '/CourseAdvertising/setPreserve',
      })
      this.form = data
    },
    async submit() {
      if (this.form.preserve == 1) {
        // if (!this.form.preserve_title.trim()) {
        //   this.$root.prompt('请先输入标题')
        //   return
        // }
        // if (!this.form.preserve_img) {
        //   this.$root.prompt('请先选择图片')
        //   return
        // }
        if (!this.form.preserve_button.trim()) {
          this.$root.prompt('请先输入按钮文字')
          return
        }
      }

      await this.$http({
        url: '/CourseAdvertising/setPreserve',
        data: this.form,
        successMsg: true,
      })
      this.cancel()
    },

    // 图片结果
    complete(val) {
      this.form.preserve_img = val
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.pageretention {
  position: relative;
  .example {
    right: 50px;
    top: 80px;
    position: absolute;
    .contain {
      margin-top: 10px;
      width: 150px;
      height: 250px;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;

      .tu {
        width: 130px;
        height: 130px;
        border-radius: 5px;
        background-color: #f5f5f5;
        overflow: hidden;
        position: relative;
        .icon_close {
          position: absolute;
          top: 2px;
          right: 2px;
          color: #fff;
          font-size: 12px;
        }
        .title_button {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .title {
            color: #fff;
            font-size: 14px;
            margin-top: 20px;
            text-align: center;
          }
          .button {
            background-color: rgb(219, 176, 34);
            font-size: 13px;
            min-width: 100px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            margin-bottom: 10px;
            border-radius: 20px;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
    min-height: 300px;
  }
  .dialogVisible {
    // ::v-deep .el-dialog__title {
    //   font-size: 14px;
    //   color: #333333;
    //   line-height: 19px;
    // }
    ::v-deep .el-dialog__header {
      padding-top: 14px;
    }
    ::v-deep .el-dialog__footer {
      text-align: left;
    }
    .dialogVisible_bottom {
      .changePhotoimg {
        height: 0 !important;
        width: 0 !important;
      }
      .coustom_contain {
        padding: 10px;
        width: 450px;
        display: flex;
        background: #f5f5f5;
        .tu {
          width: 100px;
          height: 100px;
          background: #fff;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          .tip {
            font-size: 14px;
            color: #333333;
            margin-bottom: 20px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
</style>
