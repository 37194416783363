<template>
  <div id="coursePromotion">
    <div v-if="$route.name == 'tuiguang'">
      <div class="course-message">
        <div>{{ coursePromotionMessage1 }}</div>
        <div>{{ coursePromotionMessage2 }}</div>
        <div>{{ coursePromotionMessage3 }}</div>
      </div>
      <div class="data_overview">
        <div class="title flex-center">
          数据概况
          <div class="getDataTime">
            统计时间：{{
              headerInfo.time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
            }}
          </div>
        </div>
        <div class="flexBox">
          <flexBox1
            :list="[
              {
                top: '累计订单',
                bottom: headerInfo.orderCount,
                name: Math.random(),
                prompt: '购买商品事件类型的累计订单数',
              },
              {
                top: '累计表单',
                bottom: headerInfo.formCount,
                name: Math.random(),
                prompt: '表单提交事件类型的累计表单数',
              },
            ]"
          ></flexBox1>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <div class="content-btn-left">
            <el-button size="medium" class="btn" @click="coursePromotionClick">
              新建活动
            </el-button>
            <el-button class="mr10" size="medium" @click="setBlackwhite">
              黑/白名单设置
            </el-button>
            <el-button class="mr20" size="medium" @click="status = true">
              页面留存设置
            </el-button>
            <el-link
              size="medium"
              type="primary"
              class="link"
              @click="linkClick"
            >
              使用教程
            </el-link>
          </div>
          <div class="content-right">
            <div class="allPromotionState">
              <el-select
                size="medium"
                v-model="post3.type"
                placeholder="全部推广状态"
              >
                <el-option
                  v-for="item in allPromotionState.optionsPromotionState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <el-input
              class="inputclass"
              size="medium"
              v-model="post3.name"
              :placeholder="
                post3.search_type == 1 ? '输入名称搜索' : '输入二维码备注搜索'
              "
              @keyup.enter.native="post = Object.assign({}, post3)"
            >
              <el-select
                v-model="post3.search_type"
                style="width:120px"
                size="medium"
                slot="prepend"
              >
                <el-option label="名称" :value="1"></el-option>
                <el-option label="二维码备注" :value="2"></el-option>
              </el-select>
            </el-input>

            <!-- <el-input
              size="medium"
              v-model="post3.name"
              placeholder="输入名称搜索"
              @keyup.enter.native="post = Object.assign({}, post3)"
            ></el-input> -->

            <el-button
              class="ml20"
              size="medium"
              type="primary"
              @click="post = Object.assign({}, post3)"
            >
              搜索
            </el-button>
          </div>
        </div>
        <div class="list">
          <pagination2
            :option="post"
            ref="advertisingList"
            url="/CourseAdvertising/advertisingList"
            @complete="tableCallback"
          >
            <template v-slot:default="{ tableData }">
              <div class="tablecontain">
                <el-table
                  ref="dialogTable"
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                  :data="tableData"
                  style="width: 100%"
                  class="table_row"
                >
                  <el-table-column
                    label="活动名称"
                    prop="name"
                  ></el-table-column>
                  <el-table-column label="事件类型" width="120px">
                    <div slot="header">
                      事件类型
                      <helpIcon
                        style="margin-left: 6px"
                        content="表单提交：</br>抖音/头条--表单提交(form)</br>
            百度--表单提交成功（CT=3）</br></br>
商品购买：</br>抖音/头条--商品购买(shopping)</br>
            百度--订单提交成功（CT=14）</br></br>
添加企业微信：</br>抖音/头条--微信_添加企业微信(work_wechat_added)</br>
            百度--微信加粉成功（CT=79） "
                      ></helpIcon>
                    </div>
                    <template slot-scope="{ row }">
                      {{
                        row.type == 1
                          ? '表单提交'
                          : row.type == 2
                          ? '商品购买'
                          : '添加企业微信'
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="商品" min-width="200">
                    <template slot-scope="{ row }">
                      <template v-if="!row.course || row.course.length == 0">
                        -
                      </template>
                      <template v-else>
                        <div class="contain_course">
                          <div class="course_img">
                            <img :src="row.course.photo" alt="" />
                          </div>
                          <div class="name_price">
                            <div :title="row.course.name" class="name">
                              {{ row.course.name }}
                            </div>
                            <div class="course_price">
                              <div class="t1">
                                {{
                                  row.course.price_type == 1
                                    ? '免费'
                                    : row.course.price_type == 2
                                    ? '密码'
                                    : '￥' + row.course.price
                                }}
                              </div>
                              <!-- <div class="t2" v-if="row.course.costprice > 0">
                                {{
                                  row.course.costprice == ''
                                    ? ''
                                    : '￥' + row.course.costprice
                                }}
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column label="二维码备注" min-width="120px">
                    <template slot-scope="{ row }">
                      {{ row.remark }}
                    </template>
                  </el-table-column>
                  <el-table-column label="创建时间">
                    <template slot-scope="{ row }">
                      {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
                    </template>
                  </el-table-column>
                  <el-table-column label="创建人" prop="uname">
                    <template slot-scope="{ row }">
                      {{ row.uname || '-' }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="表单/订单（数量）"
                    width="150px"
                    prop="submit_count"
                  >
                    <template slot-scope="{ row }">
                      {{ row.submit_count || '-' }}
                    </template>
                  </el-table-column>

                  <el-table-column label="操作" min-width="200">
                    <template slot-scope="{ row }">
                      <div class="function">
                        <div
                          class="touFangDiv"
                          @click="selectTouFangClick(row)"
                        >
                          <span
                            class="hoverSpan"
                            @click="toufangDialogVisible = true"
                          >
                            投放链接
                          </span>
                        </div>
                        <el-divider
                          direction="vertical"
                          class="divier"
                        ></el-divider>
                        <el-button type="text" @click="detail(row)">
                          统计
                        </el-button>
                        <el-divider
                          direction="vertical"
                          class="divier"
                        ></el-divider>
                        <el-button
                          type="text"
                          @click="handleCommand(row.ca_id, row.status, 'edit')"
                        >
                          编辑
                        </el-button>
                        <el-divider
                          direction="vertical"
                          class="divier"
                        ></el-divider>
                        <el-button
                          type="text"
                          @click="
                            handleCommand(row.ca_id, row.status, 'delete')
                          "
                        >
                          删除
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </pagination2>

          <!-- 投放链接 -->
          <el-dialog
            title="投放链接"
            v-if="toufangDialogVisible"
            :visible.sync="toufangDialogVisible"
            width="800px"
            :close-on-click-modal="false"
          >
            <div class="touFangURL" v-for="item in tfURL" :key="item.id">
              <div class="contain">
                <div class="left">
                  <div class="title_name">
                    {{ item.name }}
                  </div>
                  {{ item.url }}
                </div>

                <el-button
                  size="mini"
                  class="btn copyBtn"
                  type="primary"
                  v-if="!cancel_token && item.id == 4"
                  :disabled="true"
                >
                  复制推广链接
                </el-button>
                <el-button
                  v-else
                  size="mini"
                  class="btn copyBtn"
                  type="primary"
                  :data-clipboard-text="item.url"
                >
                  复制推广链接
                </el-button>
              </div>
              <div class="baidu_token" v-if="item.id == 4">
                <!-- :autosize="{ minRows: 1, maxRows: 6 }" 
                  type="textarea" -->
                token：
                <el-input
                  v-if="editing"
                  style="width:550px"
                  class="mr10"
                  v-model="baidu_token"
                  size="medium"
                  placeholder="请填写百度token，用于回传联调测试"
                ></el-input>
                <div v-else class="mr10 show_token">
                  <div style="width:100%;overflow:hidden">
                    <span v-if="baidu_token">
                      {{ baidu_token }}
                    </span>
                    <span v-else style="color:#c0c4cc">
                      请填写百度token，用于回传联调测试
                    </span>
                  </div>
                </div>

                <el-button type="text" v-if="!editing" @click="editer(1)">
                  编辑
                </el-button>
                <template v-else>
                  <el-button type="text" @click="save">保存</el-button>
                  <div class="dirver">|</div>
                  <el-button type="text" @click="editer(2)">取消</el-button>
                </template>
              </div>

              <!-- {{ tfURL }} -->
            </div>
            <!-- <div class="touFangFoot">
              <span>使用提示：复制投放链接->进入巨量后台->投放完成</span>
              <el-button class="btn copyBtn" :data-clipboard-text="tfURL">
                复制链接
              </el-button>
            </div> -->
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- 黑白名单修改 -->
    <el-dialog
      title="黑/白名单设置"
      :visible.sync="blackWhitelist"
      width="800px"
      class="blackWhitelist"
      v-if="blackWhitelist"
    >
      <div class="blackWhitelist_contain">
        <div class="white mr20">
          <div>
            <div class="title">手机号白名单</div>
            <div class="tip">
              提示：重复提交的手机号不会回传给广告平台，加入白名单的手机号不受限制
            </div>
          </div>
          <div class="contain" @click="showInput">
            <el-tag
              :key="tag"
              v-for="tag in whiteTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-if="whiteinputVisible"
              placeholder="请输入白名单手机号"
              class="input-new-tag"
              v-model="whiteinputValue"
              @input="whiteinputValue = whiteinputValue.replace(/[^\d]/g, '')"
              ref="saveTagInput"
              size="small"
              style="width:160px"
              @keyup.enter.native="handleInputConfirmenter"
              @blur="handleInputConfirm"
            ></el-input>
            <!-- v-if="whiteinputVisible" -->
            <el-button
              class="button-new-tag"
              v-else
              size="small"
              @click="showInput"
            >
              请输入白名单手机号
            </el-button>
          </div>
        </div>
        <div class="black">
          <div>
            <div class="title">手机号黑名单</div>
            <div class="tip">提示：加入黑名单的手机号无法获取短信验证码</div>
          </div>
          <div class="contain" @click="showInput2">
            <el-tag
              :key="tag"
              v-for="tag in blackTags"
              closable
              :disable-transitions="false"
              @close="handleClose2(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-if="blackinputVisible"
              placeholder="请输入黑名单手机号"
              class="input-new-tag"
              v-model="blackinputValue"
              @input="blackinputValue = blackinputValue.replace(/[^\d]/g, '')"
              ref="saveTagInput2"
              size="small"
              style="width:160px"
              @keyup.enter.native="handleInputConfirmenter2"
              @blur="handleInputConfirm2"
            ></el-input>
            <!-- v-if="whiteinputVisible" -->
            <el-button
              class="button-new-tag"
              v-else
              size="small"
              @click="showInput2"
            >
              请输入黑名单手机号
            </el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="blackWhitelist = false" size="medium">
          取 消
        </el-button>
        <el-button type="primary" size="medium" @click="upPhoneList">
          保存
        </el-button>
      </span>
    </el-dialog>
    <!-- 页面留存设置 -->
    <page-retention v-if="status" :dialogstatus.sync="status"></page-retention>
    <router-view />
  </div>
</template>

<script>
import pageRetention from './pageRetention.vue'
import bg from '@/mixin/background'
import ClipboardJS from 'clipboard'
import flexBox1 from '@/components/flex/flexBox1'
export default {
  name: 'coursePromotion',
  components: {
    flexBox1,
    pageRetention,
  },
  data() {
    return {
      // 页面留存设置
      status: false,

      cancel_token: '',
      // 百度token
      baidu_token: '',
      // 编辑百度token
      editing: false,

      // 黑白名单
      blackWhitelist: false,
      whiteTags: [],
      whiteinputVisible: false,
      whiteinputValue: '',

      blackTags: [],
      blackinputVisible: false,
      blackinputValue: '',

      selectVal: {},
      options3: [],
      headerInfo: {},
      post: {
        target_type: 1,
        course_status: 0,
        type: 0,
        name: '',
      },
      post3: {
        target_type: 1,
        course_status: 0,
        type: 0,
        name: '',
        search_type: 1,
      },
      post2: {
        ca_id: '',
      },
      coursePromotionMessage1:
        '推广招生指将网校的课程投放至抖音、头条、百度等推广渠道中，通过免费或低价的体验课，吸引用户报名体验试听，从而转化为VIP课程；使用叮叮开课的课程推广功能，自动生成对应推广渠道的投放链接，学员报名后自动注册账号并弹出指定的二维码，引流、报名、转化全流程闭环，省去运营、设计、开发以及服务器资源等成本。',
      coursePromotionMessage2:
        '机构操作流程：新建课程->添加课程推广->设置属性->复制投放链接->进入巨量后台->投放完成',
      coursePromotionMessage3:
        '学员转化流程：抖音信息流广告->报名课程->输入手机号并付款->报名成功->显示二维码和网校入口引导->进入微信网校->查看已购课程',
      allPromotionState: {
        optionsPromotionState: [
          {
            value: 0,
            label: '全部类型',
          },
          {
            value: 1,
            label: '表单提交',
          },
          {
            value: 2,
            label: '商品购买',
          },
          {
            value: 3,
            label: '添加企业微信',
          },
        ],
      },
      toufangDialogVisible: false,
      toufangRadio: 'douYin',
      tfURL: '',
      openCourseSelect: false,
    }
  },

  mixins: [bg],

  created() {
    this.getphoneList()
  },

  methods: {
    // 编辑百度token
    editer(val) {
      this.editing = !this.editing
      if (val == 2) {
        this.baidu_token = this.cancel_token
      }
    },

    async save() {
      await this.$http({
        url: 'CourseAdvertising/setBaiduToken',
        data: {
          ca_id: this.selectCaid,
          baidu_token: this.baidu_token,
        },
        successMsg: true,
      })
      this.cancel_token = this.baidu_token
      this.$refs.advertisingList.reset()
      this.editer()
    },

    // 黑白名单保存
    async upPhoneList() {
      await this.$http({
        url: '/CourseAdvertising/upPhoneList',
        data: {
          blacklist: this.blackTags,
          whitelist: this.whiteTags,
        },
        successMsg: true,
      })
      this.blackWhitelist = false
    },

    // 获取黑白名单
    async getphoneList() {
      const {
        data: { blacklist, whitelist },
      } = await this.$http({
        url: '/CourseAdvertising/phoneList',
      })
      this.whiteTags = whitelist
      this.blackTags = blacklist
    },

    // 设置标签
    handleClose(tag) {
      this.whiteTags.splice(this.whiteTags.indexOf(tag), 1)
    },
    showInput() {
      this.whiteinputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    // 点击回车
    handleInputConfirmenter() {
      this.handleInputConfirm()
      this.showInput()
    },

    handleInputConfirm() {
      let whiteinputValue = this.whiteinputValue
      if (whiteinputValue) {
        this.whiteTags.push(whiteinputValue)
      }
      this.whiteinputVisible = false
      this.whiteinputValue = ''
    },

    // 设置标签
    handleClose2(tag) {
      this.blackTags.splice(this.blackTags.indexOf(tag), 1)
    },
    showInput2() {
      this.blackinputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput2.$refs.input.focus()
      })
    },

    // 点击回车
    handleInputConfirmenter2() {
      this.handleInputConfirm2()
      this.showInput2()
    },

    handleInputConfirm2() {
      let blackinputValue = this.blackinputValue
      if (blackinputValue) {
        this.blackTags.push(blackinputValue)
      }
      this.blackinputVisible = false
      this.blackinputValue = ''
    },

    // 设置黑白名单
    setBlackwhite() {
      this.blackWhitelist = true
      this.$nextTick(() => {
        this.getphoneList()
      })
    },

    // 分组
    reset() {
      this.getGroupList()
      this.$refs.childDialogBox.reset()
    },

    // 获取机构分组
    getGroupList() {
      this.$http({
        url: '/pcUser/getGroupList',
        callback: ({ data }) => {
          this.options3 = data.map(item => {
            return {
              label: item.gname,
              value: Number(item.gid),
              count: item.number,
            }
          })
        },
      })
    },

    // 获取列表数据
    tableCallback(e) {
      this.headerInfo = {
        formCount: e.formCount,
        orderCount: e.orderCount,
        time: e.time,
      }
    },

    detail(row) {
      if (row.type == 1) {
        this.$router.push({
          path: '/tuiguang/formTongji',
          query: { ca_id: row.ca_id },
        })
      } else if (row.type == 2) {
        this.$router.push({
          path: '/tuiguang/orderTongji',
          query: { ca_id: row.ca_id },
        })
      } else {
        this.$router.push({
          path: '/tuiguang/enterpriseWechat',
          query: { ca_id: row.ca_id },
        })
      }
    },

    coursePromotionClick() {
      this.$router.push({
        path: '/tuiguang/addCoursePromotion',
      })
    },

    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.copyBtn')
      clipboard.on('success', function() {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function() {
        self.$root.prompt('复制失败')
      })
    },

    selectTouFangClick(val) {
      this.tfURL = val.url_arr
      this.selectCaid = val.ca_id
      this.baidu_token = val.baidu_token
      this.cancel_token = val.baidu_token
    },

    handleCommand(ca_id, status, command) {
      switch (command) {
        case 'edit':
          this.$router.push({
            path: '/tuiguang/addCoursePromotion',
            query: { id: ca_id },
          })
          break
        case 'deactivate':
          // console.log(command)
          //停用/启用
          this.$http({
            url: '/CourseAdvertising/stop',
            data: {
              ca_id: parseInt(ca_id),
              status: status == 1 ? 2 : 1,
            },
            callback: ({ data }) => {
              console.log('删除推广活动', data)
              //更新删除后的数据
              this.post = { ...this.post }
            },
          })
          break
        case 'delete':
          // console.log(command)
          this.$confirm('是否确定删除该推广活动', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback: action => {
              if (action == 'confirm') {
                //删除推广活动
                this.$http({
                  url: '/CourseAdvertising/delDvertising',
                  data: {
                    ca_id: parseInt(ca_id),
                  },
                  callback: ({ data }) => {
                    console.log('删除推广活动', data)

                    //更新删除后的数据
                    this.post = { ...this.post }
                  },
                })
              }
            },
          })
          break
        default:
          break
      }
    },
    linkClick() {
      this.$store.dispatch(
        'open',
        'https://www.yuque.com/dingdingkaike/doc/gwxe3n'
      )
    },
  },
  computed: {},
  updated() {},
  mounted() {
    this.initCopy()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .blackWhitelist {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    color: #333333;
    .el-icon-close::before {
      color: #999999;
    }
    .el-tag__close:hover {
      background-color: #f5f5f5;
    }
  }

  .button-new-tag {
    height: 32px;
    padding-left: 10px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    color: #999999;
    border: 0;
    &:hover {
      color: #999999;
      background-color: white;
    }
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .blackWhitelist_contain {
    display: flex;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .tip {
      font-size: 14px;
      color: #ff3535;
      line-height: 19px;
      margin: 12px 20px 20px;
    }
    .contain {
      @extend %scroll;
      padding: 10px;
      overflow: auto;
      height: 284px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      .el-input__inner {
        border: 0;
        font-size: 12px;
        padding: 0px;
        &::placeholder {
          color: #999999;
        }
      }
    }
    .white,
    .black {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

::v-deep .blackWhitelist {
  .el-dialog__body {
    overflow: auto;
    padding: 10px 20px;
    height: 500px;
    @extend %scroll;
  }
}

.dirver {
  color: #0aa29b;
  margin: 0 10px;
}
.inputclass {
  width: 300px;
  ::v-deep .el-input-group__prepend {
    background-color: #fff !important;
  }
}
img {
  height: 100%;
  width: 100%;
}

.membership {
  display: flex;
  align-items: center;
  .tu {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
  }
  .right {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.course-message {
  margin: 20px;
  padding: 20px;
  background: #ffffff;
  font-size: 13px;
  line-height: 25px;
  color: #333333;
}
.data_overview {
  padding: 20px;
  margin: 0 20px;
  background-color: #fff;
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    .getDataTime {
      font-size: 12px;
      color: #a1a1a1;
      margin-left: 10px;
      line-height: 15px;
      font-weight: 500;
    }
  }
  ::v-deep .flexBox {
    margin: 30px 0 10px;
    .flex-box1 {
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 16.67% !important;
        flex: none;
      }
    }
  }
}

.content {
  background: #fff;
  margin: 20px;
}

.content-title {
  padding: 20px 20px 0px 20px;
  height: 40px;
}

.content-btn-left {
  float: left;
}

.btn {
  margin-right: 10px;
  background: #0aa29b;
  color: #fff;
  height: 40px;
  border-radius: 4px;
}

.content-btn-left .link {
  color: #0aa29b;
}

.content-right {
  display: inline-flex;
  float: right;
}

.content-right .allPromotionState {
  width: 140px;
  margin: 0 20px;
  font-size: 14px;
}

::v-deep .el-select {
  // width: 150px;
}

.list {
  margin: 30px 20px 20px 20px;
}

.course_img img {
  width: 100px;
  height: 57px;
}

.contain_course {
  display: flex;
}

.name_price {
  flex: 1;
  margin-left: 10px;
  height: 57px;
  width: calc(100% - 110px);
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.course_price {
  margin-top: 14px;
  display: flex;
}

.course_price .t1 {
  min-width: 50px;
  color: #ff3535;
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.course_price .t2 {
  flex: 1;
  color: #999999;
  text-decoration: line-through;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80px;
}

.hoverSpan {
  color: #0aa29b;
  cursor: pointer;
}

.greyClass {
  color: #bdbebd;
}

.divier {
  background: #0aa29b;
}

.function {
  .el-dropdown {
    vertical-align: middle;
  }
}

.touFangFoot {
  margin-top: 20px;
  height: 40px;
}

.touFangFoot span {
  line-height: 40px;
}

.touFangURL {
  margin-bottom: 20px;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 4px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  .contain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      margin-right: 20px;
      .title_name {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .baidu_token {
    display: flex;
    align-items: center;
  }
  .show_token {
    overflow: hidden;
    white-space: nowrap;
    min-height: 33px;
    font-size: inherit;
    width: 550px;
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 5px 15px;
    line-height: 1.5;
  }
}

.copyBtn {
  float: right;
}

.selectBlue {
  color: #368fdd;
}

.selectRed {
  color: #ff3535;
}

.course-del {
  font-size: 12px;
}

.touFangDiv {
  display: inline-block;
}
</style>
