import { render, staticRenderFns } from "./coursePromotion.vue?vue&type=template&id=34eb498a&scoped=true&"
import script from "./coursePromotion.vue?vue&type=script&lang=js&"
export * from "./coursePromotion.vue?vue&type=script&lang=js&"
import style0 from "./coursePromotion.vue?vue&type=style&index=0&id=34eb498a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34eb498a",
  null
  
)

export default component.exports